<template>
    <a-modal
        title="更换商家"
        width='50%'
        :visible="visible"
        :confirmLoading="confirmLoading"
        :destroyOnClose="true"
        @ok="handleAuditBatch"
        @cancel="handleCancel"
    >
        <a-form :form="form">
          
          <a-form-item label="选择商家">
            <a-select v-decorator="['storeCustomerId',{rules: [{ required: true, message: '请选择商家！' }]}]" allow-clear placeholder="请选择">
              <a-select-option v-for="(item, index) in storeList" :key="index"
                               :value="item.id">
                {{ item.nickname }}
              </a-select-option>
            </a-select>
          </a-form-item>
          </a-form>
    </a-modal>
</template>

<script>
import {changeStore} from "@/api/modular/mallLiving/agent";

export default {
  name:'updateStoreForm',
  props:{
    storeList: {
      required: true,
      type: Array,
      default:()=> []
    }
  },
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            batchData:{},   //父组件传过来的数据
            form: this.$form.createForm(this),
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
          customerId:''
        }
    },
    methods:{
      //初始化方法
      show(customerId){
        this.visible = true
        this.customerId = customerId
      },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.confirmLoading = false
          this.$nextTick(()=> {
            this.form.setFieldsValue({
              storeCustomerId:''
            })
            this.customerId = ''
          })
          
        },
         //批量审核
        handleAuditBatch() {
            this.form.validateFields((errors, values) => {
                if(!errors){
                  changeStore({...values, customerId:this.customerId}).then((res) => {
                        if(res.success){
                            this.$message.success('更换商家')
                            this.$emit("ok")
                            this.handleCancel()
                        }else{
                            this.$message.error("操作失败")
                            setTimeout(()=>{
                                this.confirmLoading = false
                            },600)
                        }
                    })
                }
            })
            
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding-left:7px;
            font-size:15px;
        }
    }
    img{
        width:100px;
        height: 50px;
    }
    .flex {
        display: flex;
    }
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
</style>