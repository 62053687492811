<template>
    <a-modal
        title="详情"
        width='50%'
        :visible="visible"
        :destroyOnClose="true"
        @cancel="handleCancel"
        :footer="null"
    >
        <!-- <a-spin :spinning="confirmLoading">
            <table class="mytable">
                <tr>
                    <td class="title">申请人姓名</td>
                    <td>{{details.realName}}</td>
                    <td class="title">用户昵称</td>
                    <td>{{details.nickName}}</td>
                </tr>
                <tr>
                    <td class="title">身份证号</td>
                    <td>{{details.idNo}}</td>
                    <td class="title">手机号码</td>
                    <td>{{details.mobile}}</td>
                </tr>
                <tr>
                    <td class="title">申请时间</td>
                    <td>{{details.createTime}}</td>
                    <td class="title">审核结果</td>
                    <td :style="{color:details.result==1?'orange':details.result==2?'green':'red'}">
                        {{details.result==1 ? '待审核' : details.result==2 ? '已通过' : details.result==3 ? '已拒绝' : ''}}
                    </td>
                </tr>
                <tr>
                    <td class="title">身份证正面</td>
                    <td>
                        <img :src="details.idFront" @click="preVisible" alt="">

                        <a-modal 
                            v-show="details.idFront" 
                            :visible="previewVisible" 
                            :footer="null" 
                            @cancel="Cancelpreview"
                        >
                            <img style="width: 100%;height:100%;padding:10px;" :src="details.idFront" />
                        </a-modal>
                    </td>
                    <td class="title">身份证反面</td>
                    <td>
                        <img :src="details.idBack" @click="preVisible1" alt="">
                        <a-modal 
                            v-show="details.idBack" 
                            :visible="previewVisible1"
                            :footer="null" 
                            @cancel="Cancelpreview1"
                        >
                            <img style="width: 100%;height:100%;padding:10px;" :src="details.idBack" />
                        </a-modal>
                    </td>
                </tr>
                <tr>
                    <td class="title">审核备注</td>
                    <td colspan="3">{{details.remark}}</td>
                </tr>
            </table>
        </a-spin> -->
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form layout="inline">
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="申请人姓名">
                            <span>{{details.realName}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="用户昵称">
                            <span>
                                {{details.nickName}}
                            </span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="身份证号">
                            <span>{{details.idNo}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="手机号码">
                            <span>{{details.mobile}}</span>
                        </a-form-item>
                    </a-col>
                   
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="申请时间">
                            <span>{{details.createTime}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="审核结果">
                            <span :style="{color:details.result==1?'orange':details.result==2?'green':'red'}">
                                {{details.result==1 ? '待审核' : details.result==2 ? '已通过' : details.result==3 ? '已拒绝' : ''}}
                            </span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="身份证正面">
                            <span>
                                <viewer :images="[details.idFront]">
                                    <img :src="details.idFront" alt="">
                                </viewer>
                            </span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="身份证反面">
                            <span>
                                <viewer :images="[details.idBack]">
                                    <img :src="details.idBack" alt="">
                                </viewer>
                            </span>
                        </a-form-item>
                    </a-col>
                     <a-col :md="24" :sm="24" v-if="details.idBeginTime">
                        <a-form-item  label="身份证日期">
                            <span>{{details.idBeginTime ? details.idBeginTime.split(" ")[0] : ''}}{{details.idEndTime ? '~~'+details.idEndTime.split(" ")[0] : ''}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24" v-if="details.result!=1">
                        <a-form-item  label="审核备注">
                            <span>{{details.remark}}</span>
                        </a-form-item>
                    </a-col>
                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { auditDetail } from '@/api/modular/mallLiving/check/idCheck'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            previewVisible: false,
            previewVisible1: false,
            id:'',
            details:{},
            form: this.$form.createForm(this),
        }
    },
    methods:{
        //初始化方法
        detail(record){
            this.visible = true
            this.confirmLoading = true
            //获取详情数据
            setTimeout(()=>{
                auditDetail({id: record.id}).then((res)=>{
                    if(res.success){
                        this.details = res.data
                        this.confirmLoading = false
                    }
                }).finally((res)=>{
                    setTimeout(()=>{
                        this.confirmLoading = false
                    },5000)
                })
            },100)
            
        },
        // 身份证正面大图显示与隐藏
        preVisible(){
            this.previewVisible = true;
        },
        Cancelpreview(){
            this.previewVisible = false;
        },
        // 身份证反面大图显示与隐藏
        preVisible1(){
            this.previewVisible1 = true;
        },
        Cancelpreview1(){
            this.previewVisible1 = false;
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.confirmLoading = false
            setTimeout(()=>{
                this.details = {} //关闭之后清空
            },100)
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding:7px;
            font-size:15px;
            width:30%;
        }
    }
    img{
        width:80px;
        height: 50px;
    }
</style>