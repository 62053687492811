<template>
    <a-modal
        :title="`${oneData.result==2?'通过':'拒绝'}`"
        width='50%'
        :visible="visible"
        :confirmLoading="confirmLoading"
        :destroyOnClose="true"
        @ok="handleAuditOne"
        @cancel="handleCancel"
    >
        <a-form :form="form">
            <!-- 父级 -->
            <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input 
                    v-decorator="['remark',{rules: [{ required: oneData.result=='2' ? false : true, message: '请输入！' }]}]" 
                    type="textarea"
                    placeholder="请输入"
                />
            </a-form-item>
          </a-form>
    </a-modal>
</template>

<script>
import { auditOne } from '@/api/modular/mallLiving/check/idCheck'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            oneData:{},  //父组件传过来的result和id
            form: this.$form.createForm(this),
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        }
    },
    methods:{
        //初始化方法
        one(data){
            this.visible = true
            this.oneData = data
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.form.setFieldsValue({
                remark:''
            })
        },
        handleAuditOne() {
            this.form.validateFields((errors, values)=>{
                if(!errors){
                    auditOne({...this.oneData, remark:values.remark}).then((res) => {
                        if(res.success){
                            let text = this.oneData.result==2 ? "通过成功" : "拒绝成功"
                            this.$message.success(text)
                            this.$emit("ok")
                            this.handleCancel()
                        }else{
                            this.$message.error("操作失败")
                            setTimeout(()=>{
                                this.handleCancel()
                            },3000)
                        }
                    })
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding-left:7px;
            font-size:15px;
        }
    }
    img{
        width:100px;
        height: 50px;
    }
    .flex {
        display: flex;
    }
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
</style>