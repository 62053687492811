//代理商接口
import { axios } from '@/utils/request'
/*
 * @Description:
 * @Version:
 * @Author: 彭勃
 * @Date: 2022-03-8
 */

//业务员列表分页查询
export function agentPageList(parameter){
    return axios({
        url: '/realNameAuthentication/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}

// 身份认证审核更改代理商
export function changeStore(data){
  return axios({
    url: '/customer/changeStore',
    method: 'post',
    data: data
  })
}


//代理商详情
export function agentDetail(parameter){
    return axios({
        url: '/agent/detail',
        method: 'post',
        params: parameter
    })
}


//代理商修改状态
export function agentDelete(parameter){
    return axios({
        url: '/agent/delete',
        method: 'post',
        data: parameter
    })
}

export function findAgentTaskList(paramter){
	return axios({
		url: '/agent/taskList',
		method: 'post',
		params: paramter
	})
}
