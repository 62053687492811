import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-01-18 14:56:50
 */

//获取表格数据，以及筛选
export function getPageList(parameter){
    return axios({
        url: '/realNameAuthentication/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//批量审核
export function auditBatch(parameter){
    return axios({
        url: '/realNameAuthentication/batchAudit',
        method: 'post',
        data: parameter
    })
}
//单独审核
export function auditOne(parameter){
    return axios({
        url: '/realNameAuthentication/audit',
        method: 'post',
        data: parameter
    })
}
//详情
export function auditDetail(parameter){
    return axios({
        url: '/realNameAuthentication/detail',
        method: 'post',
        params: parameter
    })
}